/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Segment, Image, Grid, List } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { asyncConnect } from '@plone/volto/helpers';
import { UniversalLink, Icon } from '@plone/volto/components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Helmet, toBackendLang } from '@plone/volto/helpers';
import { Link } from 'react-router-dom';
import { getNavigation } from '@plone/volto/actions';
import { SocialIcon } from 'react-social-icons';
import config from '@plone/volto/registry';
import ReactGA from "react-ga4";
import PacLogo from './pacioos_logo.png';
import OTNLogo from './otn_logo_white.svg';

class Footer extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    getNavigation: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { settings } = config;
    if (settings.isMultilingual) {
      this.props.getNavigation(`${toBackendLang(this.props.lang)}`, 4);
    } else {
      this.props.getNavigation('', 4);
    }
  }
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  renderItems = (items) => {
    return (
      <ul id="snd_column">
        {items.map((item) => (
          <li
            key={item.url}
            className={item.items?.length > 0 ? 'with-children' : ''}
          >
            <Link to={item.url}>{item.title}</Link>
            {/* {item.items && this.renderItems(item.items)} */}
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const { items } = this.props;
    const year = new Date().getFullYear();
    const { settings } = config;
    const ga_key = config?.settings?.ga4_key;
    if (ga_key){
      ReactGA.initialize(ga_key);
    }
    return (
      <Segment
        role="contentinfo"
        vertical
        padded
        // inverted
        // color="grey"
        textAlign="center"
        id="footer"
        style={{ 'paddingTop': '2em' }}
      >
        <Container>
          <div style={{"display":  "flex", "justifyContent": "center"}}>
            <a href="http://pacioos.hawaii.edu/" style={{"padding":"1em 2em 2em 1em"}}><Image src={PacLogo} height={59}/></a>
            <a href="https://oceantrackingnetwork.org/"><Image src={OTNLogo}  height={90}/></a>
          </div>
        </Container>
      </Segment>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      items: state.navigation.items,
      lang: state.intl.locale,
    }),
    { getNavigation },
  ),
  asyncConnect([
    {
      key: 'navigation',
      promise: ({ location, store: { dispatch, getState } }) => {
        const { settings } = config;
        const lang = getState().intl.locale;
        if (settings.isMultilingual) {
          return (
            __SERVER__ && dispatch(getNavigation(`${toBackendLang(lang)}`, 4))
          );
        } else {
          return __SERVER__ && dispatch(getNavigation('', 4));
        }
      },
    },
  ]),
)(Footer);
