import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { ListItem, ListIcon, ListContent, List } from 'semantic-ui-react'

import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import 'remixicon/fonts/remixicon.css'

const DefaultTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <UniversalLink href={href}>{linkTitle || href}</UniversalLink>;
  }

  return (
    <>
    <List>{items.map((item) => (
    <ListItem key={item['@id']}>
            <ListContent>
              {item['@type'].toLowerCase() === 'document' ? <i className="ri-file-text-line"></i>:''}
              {item['@type'].toLowerCase() === 'link' ? <i className="ri-links-line"></i>:''}
              {item['@type'].toLowerCase() === 'news item' ? <i className="ri-news-line"></i>:''}
              {item['@type'].toLowerCase() === 'image' ? <i className="ri-file-image-line"></i>:''}
              {item['@type'].toLowerCase() === 'file' ? <i className="ri-file-line"></i>:''}
              {item['@type'].toLowerCase() === 'event' ? <i className="ri-calendar-2-line"></i>:''}
              {item['@type'].toLowerCase() === 'folder' ? <i className="ri-folder-line"></i>:''}
            <ConditionalLink item={item} condition={!isEditMode} style={{'paddingLeft':'8px'}}>
              {item.title ? item.title : item.id}
            </ConditionalLink>
            </ListContent>
    </ListItem>
    ))}
    </List>
    {link && <div className="footer">{link}</div>}
    </>
  );
};
DefaultTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default DefaultTemplate;
